import './App.css';
import React from "react";
import axios from 'axios';
import {useState} from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Container } from '@mui/system';
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import * as amplitude from '@amplitude/analytics-browser';
import { pageViewTrackingPlugin } from '@amplitude/plugin-page-view-tracking-browser';


const pageViewTracking = pageViewTrackingPlugin(amplitude, {
  trackOn: undefined,
  trackHistoryChanges: undefined,
});

amplitude.add(pageViewTracking);


function App() {
  const [listOfProducts, setListOfProduct] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState('');
  const [prompt, setPrompt] = useState();
  const [temperature] = useState(0.7);

  const theme = createTheme();

  const handleSubmit = async () => {
    amplitude.track('User sent new Prompt', {prompt, temperature})
    setIsLoading(true);
    setListOfProduct("");
    try {
      const {data} = await axios.get('/.netlify/functions/getGifts', {
        headers: {
          Accept: 'application/json',
        },
        params: {
          prompt,
          temperature
        }
      });

      console.log('data is: ', JSON.stringify(data, null, 4));

      const cleanData = formatData(data.message)
      setListOfProduct(cleanData);
      amplitude.track('User received result', {result: cleanData})
      
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const formatData = (data) => {
    // split by line break and remove empty
    let cleanData = data.split(/\r?\n|\r|\n/g).filter(n => n);
    return cleanData.map(s => s.replace(/[^a-zA-Z0-9 ]/g, ''))
  } 

  const handleChange = event => {
    setPrompt(event.target.value);

    // console.log('value is:', event.target.value);
  };

  const ListOfProducts = () => {
    
  return listOfProducts.map((gift) => {
      const hrefData = "https://www.amazon.com/gp/search?ie=UTF8&tag=airecommends-20&linkCode=ur2&linkId=d640870b590231973d39ad866be08853&camp=1789&creative=9325&index=aps&keywords=" + gift
      return (
        <Grid key={gift.toString()} item xs={4} textAlign="center">
          <Card variant="" className="GiftCard" sx={{background: '#1976d20f'}}> 
            <CardContent>
              <Typography variant="body1">{gift}</Typography>
              <Link rel="noreferrer" target="_blank" href={hrefData}>Buy on Amazon</Link>
            </CardContent>
          </Card>
        </Grid>
      )
    })
  }

  return (
    <ThemeProvider theme={theme}>
      <Box>
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h6" component="div">
              AI Recommends Gifts
            </Typography>
          </Toolbar>
        </AppBar>
      </Box>


          
        <Container component="main">
          <Box sx={{ mt: 5 }}>
            <Typography variant="h2">Use AI to get gifts ideas</Typography>
            <Typography variant="subtitle1">Write a small description of the person that you want to give a gift to, like you would describe the person to someone else.</Typography>

              <TextField sx={{ mt: 2 }}
                id="personDescription"
                multiline
                rows={5}
                cols={100}
                onChange={handleChange}
                value={prompt}
                fullWidth
              />
              <Box textAlign='center'>
                <Button sx={{ mt: 3, mb: 2 }} variant="contained" type="submit" onClick={handleSubmit}>Get list of gifts</Button>
              </Box>
            </Box>


          {err && <h2>{err}</h2>}
          {isLoading && <h2>Loading...</h2>}

          <Grid container spacing={2} sx={{ mt: 3 }}>
            {listOfProducts && <ListOfProducts/>}
          </Grid>

        <Typography variant="h5" sx={{mt:3}}>Examples:</Typography>

          <Typography>John is a person that loves his cats, hiking and has a thing for trying out tech gadgets:</Typography>
          <List>
            <ListItem>1) A cat bed</ListItem> 
            <ListItem>2) A hiking book</ListItem> 
            <ListItem>3) A tech gadget</ListItem> 
          </List>

          <Typography> Cool and essential items for someone that loves camping:</Typography>
          <List>
            <ListItem>1) Tent</ListItem> 
            <ListItem>2) Lantern</ListItem> 
            <ListItem>3) Sunscreen</ListItem> 
            <ListItem>4) Bear horn</ListItem> 
            <ListItem>5) Sleeping bag</ListItem> 
            <ListItem>6) Bug spray</ListItem> 
          </List>


        </Container>
      </ThemeProvider>

  );
}

export default App;
